
#LandingMain {
    text-align: center;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;

    #NavBar {
        flex: 0;
    }


    #About {
    }
    #Curriculum {
    }
    #FAQ {
    }
    #Tuition {
    }
    #SchoolGroups {
    }
    #ContactInformation {
    }
    #MontessoriMaterials {
    }

    #LandingContent-container {
        flex: 1;
        overflow: auto;
    }
    #LandingContent {
        padding-top: 2rem;
        display: inline-block;
        width: 40rem;

        text-align: justify;
        max-width: 100vw;
        margin: auto;
    }

    #logo {
        max-width: 100vw;
    }
}

