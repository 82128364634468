#ManageFamilies {

    .Family-entry {
        margin: 1rem;
        margin-top: 2rem;
        border-left: 0.3rem solid #eed;
        padding: 0.5rem;
    }

    .Family-Student-entry {
        margin-left: 2rem;
        margin-top: 0.3rem;
    }

    .new-family {
        margin-left: 3rem;
    }


    .pin {
        width: 4rem;
    }

    input, select, button {
        margin-right: 0.5rem;
    }


    .unsaved {
        color: #46960E;
    }
}
