
#PreviousDailyVideos {
    display: flex;
    flex-direction: column;
    align-items: center;

    .entry {
        margin-top: 3rem;
        display: flex;
        align-items: center;

        .date {
            padding-right: 1rem;
            font-size: 20pt;
        }
        img {
            width: 15rem;
            max-width: 100vw;
        }
    }
}
