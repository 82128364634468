#Home {
    flex-grow: 1;
    flex-shrink: 1;
    display: inline-flex;
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .video {
        display: inline-block;
        width: 100%;
        max-height: 50vh;
        max-width: 50vw;
    }

    .previous-daily-video {
    }

    .zoom-link{
        margin-top: 2rem;
    }

    /*
    .map {
        margin-top: 2rem;
        text-align: center;

        img {
            max-width: 30vw;
            max-height: 30vh;
        }
    }
    */

    .logout {
        position: fixed;
        bottom: 0.5rem;
        left: 0.5rem;
        cursor: pointer;
    }

    #cirriculum-picker {
        text-align: center;
    }
}
