html, body, #main-content {
    font-family: 'Cormorant Garamond', serif;
    font-size: 16pt;
    width: 100vw;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

mobile-max=767px;
desktop-min=768px;
nav-width=5rem;

a, a:visited, .fakelink {
    color: #2D69CC;
    font-weight: bold;
    text-decoration: none;

    &:hover {
        color: #3883FF;
    }
}


@require "[A-Z]*.styl";
@require "admin/[A-Z]*.styl";
@require "teacher/[A-Z]*.styl";
@require "landing/[A-Z]*.styl";
//@require "primary/[A-Z]*.styl";
