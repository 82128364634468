#Uploader {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 10000;
    background-color: #EEEEEE;
    width: 17rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-top: 2px solid #000;
    border-left: 2px solid #000;
    border-radius-top-left: 1rem;
    font-weight: bold;


    .Uploader-entry {
        display: flex;
        justify-content: center;
        align-items: center;

        .Uploader-filename {
            display: inline-block;
            width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 10em;
        }
        .Uploader-spinner {
            display: inline-block;
            width: 5rem;
        }
    }
}
