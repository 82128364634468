#ReviewWork {
    display: flex;
    flex-direction: column;


    .no-work-to-review {
        display: flex;
        flex-direction: column;
        height: 100vh;
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    .ReviewWork-container {
        display: flex;
        align-items: center;

    }

    .Work-entry {
        display: flex;
        align-items: center;
        justify-content: center;

        button {
            margin: 1rem;
        }

        &.reviewed {
            opacity: 0.5;
            background-color: #ddddcc;
        }
    }

    .student-lesson-time {
        display: inline-flex;
        flex-direction: column;
        width: 15rem;
        margin: 1rem;
        .student-name {
        }
        .lesson-name {
            margin-left: 2rem;
        }
        .time {
            margin-left: 2rem;
            font-style: italic;
        }
    }

    .media-thumbnail {
        display: inline-flex;
        margin: 1rem;
        width: 256px;
        height: 256px;
        align-items: center;
        justify-content: center;
    }

    .review-button-container {
        display: inline-block;
        width: 10rem;
    }


}
