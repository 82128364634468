#Curriculum {

    #curriculum-entries {
        margin-top: 2rem;
        text-align: center;
        .curriculum-table-container {
            text-align: left;
            display: inline-flex;
            width: 18rem;
            padding: 0.5rem;
            flex-direction: column;
            .header {
                font-weight: bold;
            }
        }

    }
    .curriculum-table {
        border-collapse: collapse;
        td, th {
            border-top: 1px solid black;
            vertical-align: top;
        }
        th {
            padding-top: 1rem;
        }
    }
}
