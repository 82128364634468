
.turnable {
    margin: 0 auto;
    user-select: none;
}

.turnable .page {
    height: 100%;
}

.turnable .page img {
    max-width: 100%;
    height: 100%;
}


.clickable-areas {
    display: relative;
    width: 100%;
    height: 100%;

    > div {
        display: inline-block;
        position: absolute;
        //background-color: red;
        //opacity: 0.2;
        width: 10rem;
        height: 2rem;
        cursor: pointer;

        &:hover {
            filter: brightness(110%);
        }
    }
}


#Maps {
    .compass {
        position: absolute;
        right: 8%;
        top: 12%;
        width: 5rem;
        height: 5rem;
        text-align: center;
        font-weight: bold;
        font-family: 'Homemade Apple', cursive;
        cursor: pointer;
        color: #570038;
    }
}

#Page-Overview {
    .art {
        left: 2%;
        top: 30%;
        width: 10rem;
        height: 6rem;
    }
    .geography {
        left: 25%;
        top: 10%;
        width: 12rem;
        height: 6rem;
    }
    .biology {
        left: 50%;
        top: 8%;
        width: 10rem;
        height: 5rem;
    }
    .home {
        left: 40%;
        top: 35%;
        width: 10rem;
        height: 8rem;
    }
    .music {
        left: 5%;
        top: 60%;
        width: 10rem;
        height: 6rem;
    }
    .geometry {
        left: 25%;
        top: 75%;
        width: 10rem;
        height: 5rem;
    }
    .arithmetic {
        left: 50%;
        top: 75%;
        width: 12rem;
        height: 5rem;
    }
    .language {
        left: 73%;
        top: 57%;
        width: 10rem;
        height: 6rem;
    }
    .history {
        left: 73%;
        top: 28%;
        width: 10rem;
        height: 6rem;
    }
}
