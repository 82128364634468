#FAQ {
    .question {
        margin-top: 2rem;
        font-weight: bold;
    }
    .answer {
        padding-left: 2rem;
        margin-top: 0.5rem;
        margin-bottom: 1rem;
    }
}
