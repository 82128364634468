#MainContainer {
    width: 100vw;
    min-height: 100vh;
    max-height: 100vh;
    min-width: 100vw;
    max-width: 100vw;
    //background-color: #E2D3BB;
    display: flex;
    overflow: hidden;
}

#MainContent {
    flex: 1;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}


i.icon {
    display: inline-block;
    background-repeat: no-repeat;

    height: 1rem;
    width: 1rem;

    &.large {
        height: 3rem;
        width: 3rem;
    }
}

i.icon-edit {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/edit.svg");
}
i.icon-camera {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/camera.svg");
}
i.icon-bars {
    background-image: svg-load("../assets/fontawesome/svgs/regular/bars.svg");
}
i.icon-home {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/home-heart.svg");
}
i.icon-scroll-old {
    background-image: svg-load("../assets/fontawesome/svgs/solid/scroll-old.svg");
}
i.icon-clock {
    background-image: svg-load("../assets/fontawesome/svgs/regular/clock.svg");
}
i.icon-close {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/window-close.svg");
}
i.icon-families {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/users.svg");
}
i.icon-progress {
    background-image: svg-load("../assets/fontawesome/svgs/duotone/poll-people.svg");
}

#Nav {
    flex-shrink: 0;
    flex-grow: 0;
    display: inline-flex;
    width: nav-width;
    flex-basis: nav-width;
    flex-direction: column;
    background-color: #D1C3AD;
    justify-content: stretch;
    text-align: center;
    font-weight: bold;


    i.icon-edit {
        margin-left: 0.4rem;
    }

    .editing {
        color: #FF3D47;
        .icon-edit {
            filter: invert(60%) sepia(100%) hue-rotate(310deg) saturate(1000%);

        }
    }

    a, .logout, .fakelink {
        cursor: pointer;
        flex: 0;
        display: inline-block;
        padding: 1rem;

        &:hover {
            background-color: #E0C8A3;
        }
    }

    .spacer {
        flex: 1;
    }
}


#Nav {
    padding-top: 2rem;
}
#NavTop {
    i.icon-bars {
        margin: 0.3rem;
        width: 1.5rem;
        height: 1.5rem;
        cursor: pointer;
    }
}
