

@keyframes filter-animation {
    0% {
        filter: invert(0%) sepia(0%) hue-rotate(0deg);
    }

    100% {
        filter: invert(30%) sepia(100%) hue-rotate(230deg);
    }
}


#LessonView {
    display: flex;
    flex-direction: column;
    align-items: center;

    .video {
        flex-shrink: 0;
        flex-grow: 1;
        width: 100%;

        .small-video {
            width: 30%;
            margin: auto;
            padding-bottom: 2rem;
        }
        text-align: center;
    }

    .mode {
        float: right;
        margin-left: 1rem;
        margin-right: 1rem;
    }

    .links {
        text-align: center;
        padding-left: 1rem;
        padding-top: 1rem;
        padding-right: 1rem;
        flex: 0;

        a {
            font-weight: bold;
        }
    }

    .download-link {
        margin-top: 4rem;
        font-weight: bold;
        text-align: center;
        cursor: pointer;

        img {
            width: 5rem;
            height: 5rem;
        }

        &:hover {
            animation: filter-animation 0.1s;
            filter: invert(30%) sepia(100%) hue-rotate(230deg);
        }
    }


    #SubLessons {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .SubLesson {
            position: relative;
            border: 0.3rem solid transparent;
            display: inline-flex;
            width: 256px;
            height: 300px;
            flex-direction: column


            cursor: pointer;
            margin: 1rem;
            img {
                display: inline-block;
                width: 256px;
                height: 256px;
                object-fit: contain;
            }

            .SubLesson-name {
                text-align: center;
            }


            &.editing {
                border-style: dashed;
                border-color: #000;
            }
            &.active {
                border-style: solid;
                border-color: #0f0;
            }

            img {
                max-width: 320px;
                max-height: 200px;
            }
        }
    }

    #BreadCrumbs {
        margin: 1rem;
        padding-left: 1rem;
        text-align: left;
        width: 100%;
        .breadcrumb {
            font-weight: bold;
        }
        .this-breadcrumb {
            font-weight: bold;
        }
        .gt {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }
    }

    .video-upload-container {
        display: inline-flex;
        width: calc(100% - 2rem);
        min-height: 20rem;
        border: 0.3rem dashed black; 
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &.active {
            border-style: solid;
            border-color: #0f0;
        }
    }

    .underconstruction {
        display: inline-flex;
        height: 90vh;
        align-items: center;
        justify-content: center;
    }


    .SubLessonListEditor {

    }
}

#WorkArea {
    display: flex;
    flex-direction: column;
    align-items: center;
    .Work-dropzone {
        position: relative;
        cursor: pointer;
        margin-top: 2rem;
        display: inline-flex;
        min-height: 10rem;
        min-width: 10rem;
        border: 0.2rem dashed #000;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: bold;

        .icon-camera {
            width: 3rem;
            height: 3rem;
        }


        &.active {
            border-style: solid;
            border-color: #0f0;
        }
    }

    .WorkList {
        display: flex;
        flex-wrap: wrap;
        .entry {
            display: inline-flex;
            width: 256px;
            height: 256px;
            margin: 1rem;
            align-items: center;
            justify-content: center;
        }
    }
}
