html,
body,
#main-content {
  font-family: 'Cormorant Garamond', serif;
  font-size: 16pt;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
a,
a:visited,
.fakelink {
  color: #2d69cc;
  font-weight: bold;
  text-decoration: none;
}
a:hover,
a:visited:hover,
.fakelink:hover {
  color: #3883ff;
}
#CirriculumView {
  display: flex;
  flex: 1;
}
#PrimaryMap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#PrimaryMap #MapContainer {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background-image: url("/assets/images/primary-house.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (orientation: landscape) {
  #PrimaryMap #MapContainer {
    height: 100vh;
    width: calc(100vh * 1.207872928176795);
  }
}
@media (orientation: portrait) {
  #PrimaryMap #MapContainer {
    width: 100vw;
    height: calc(100vw / 1.207872928176795);
  }
}
#PrimaryMap #MapContainer #practical-life,
#PrimaryMap #MapContainer #math-and-science,
#PrimaryMap #MapContainer #sensorial,
#PrimaryMap #MapContainer #language,
#PrimaryMap #MapContainer #cultural {
  display: inline-block;
  position: absolute;
  z-index: 1000;
}
#PrimaryMap #MapContainer #practical-life:hover,
#PrimaryMap #MapContainer #math-and-science:hover,
#PrimaryMap #MapContainer #sensorial:hover,
#PrimaryMap #MapContainer #language:hover,
#PrimaryMap #MapContainer #cultural:hover {
  cursor: pointer;
  background-image: url("/assets/images/primary-house-highlighted.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (orientation: landscape) {
  #PrimaryMap #MapContainer #practical-life:hover,
  #PrimaryMap #MapContainer #math-and-science:hover,
  #PrimaryMap #MapContainer #sensorial:hover,
  #PrimaryMap #MapContainer #language:hover,
  #PrimaryMap #MapContainer #cultural:hover {
    background-size: calc(100vh * 1.207872928176795) 100vh;
  }
}
@media (orientation: portrait) {
  #PrimaryMap #MapContainer #practical-life:hover,
  #PrimaryMap #MapContainer #math-and-science:hover,
  #PrimaryMap #MapContainer #sensorial:hover,
  #PrimaryMap #MapContainer #language:hover,
  #PrimaryMap #MapContainer #cultural:hover {
    background-size: 100vw calc(100vw / 1.207872928176795);
  }
}
#PrimaryMap #MapContainer #practical-life {
  left: 20%;
  top: 58%;
  width: 20%;
  height: 15%;
  background-position: left 25% top 68%;
}
#PrimaryMap #MapContainer #sensorial {
  left: 61%;
  top: 59%;
  width: 20%;
  height: 15%;
  background-position: left 74% top 69%;
}
#PrimaryMap #MapContainer #language {
  left: 17%;
  top: 26%;
  width: 16%;
  height: 15%;
  background-position: left 20% top 30.5%;
}
#PrimaryMap #MapContainer #math-and-science {
  left: 61%;
  top: 26%;
  width: 18%;
  height: 15%;
  background-position: left 75% top 30.5%;
}
#PrimaryMap #MapContainer #cultural {
  left: 39.5%;
  top: 56%;
  width: 18%;
  height: 34%;
  background-position: left 48% top 85%;
}
#ElementaryMap {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
#ElementaryMap #MapContainer {
  display: inline-block;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  background-image: url("/assets/images/Mont-Map-overview.jpg");
  background-size: contain;
  background-repeat: no-repeat;
}
@media (orientation: landscape) {
  #ElementaryMap #MapContainer {
    height: 70vh;
    width: calc(70vh * 1.777777777777778);
  }
}
@media (orientation: portrait) {
  #ElementaryMap #MapContainer {
    width: 100vw;
    height: calc(100vw / 1.777777777777778);
  }
}
#ElementaryMap #MapContainer > div {
  display: inline-block;
  position: absolute;
  z-index: 1000;
}
#ElementaryMap #MapContainer > div:hover {
  background-color: #008000;
  opacity: 0.3;
  cursor: pointer;
  background-size: contain;
  background-repeat: no-repeat;
}
@media (orientation: landscape) {
  #ElementaryMap #MapContainer > div:hover {
    background-size: calc(100vh * 1.777777777777778) 100vh;
  }
}
@media (orientation: portrait) {
  #ElementaryMap #MapContainer > div:hover {
    background-size: 100vw calc(100vw / 1.777777777777778);
  }
}
#ElementaryMap #MapContainer #arithmetic {
  left: 50%;
  top: 69%;
  width: 16%;
  height: 17%;
  background-position: left 25% top 68%;
}
#ElementaryMap #MapContainer #art {
  left: 14%;
  top: 24%;
  width: 12%;
  height: 24%;
  background-position: left 74% top 69%;
}
#ElementaryMap #MapContainer #biology {
  left: 50%;
  top: 15%;
  width: 18%;
  height: 17%;
  background-position: left 20% top 30.5%;
}
#ElementaryMap #MapContainer #geography {
  left: 30%;
  top: 16%;
  width: 20%;
  height: 20%;
  background-position: left 75% top 30.5%;
}
#ElementaryMap #MapContainer #geometry {
  left: 27%;
  top: 69%;
  width: 20%;
  height: 17%;
  background-position: left 25% top 68%;
}
#ElementaryMap #MapContainer #history {
  left: 71%;
  top: 35%;
  width: 18%;
  height: 20%;
  background-position: left 48% top 85%;
}
#ElementaryMap #MapContainer #language {
  left: 71.5%;
  top: 59%;
  width: 18%;
  height: 18%;
  background-position: left 48% top 85%;
}
#ElementaryMap #MapContainer #music {
  left: 13%;
  top: 54%;
  width: 14%;
  height: 23%;
  background-position: left 48% top 85%;
}
#Home {
  flex-grow: 1;
  flex-shrink: 1;
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  justify-content: center;
/*
    .map {
        margin-top: 2rem;
        text-align: center;

        img {
            max-width: 30vw;
            max-height: 30vh;
        }
    }
    */
}
#Home .video {
  display: inline-block;
  width: 100%;
  max-height: 50vh;
  max-width: 50vw;
}
#Home .zoom-link {
  margin-top: 2rem;
}
#Home .logout {
  position: fixed;
  bottom: 0.5rem;
  left: 0.5rem;
  cursor: pointer;
}
#Home #cirriculum-picker {
  text-align: center;
}
.ImageMap {
  display: inline-block;
  margin: auto;
}
.ImageMap img {
  width: 100%;
  height: 100%;
}
.ImageMap .map-container {
  display: inline-block;
  position: relative;
}
.ImageMap .map-container .region {
  position: absolute;
  display: inline-block;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.ImageMap .map-container .region.highlight {
  background-color: #800080;
  opacity: 0.5;
}
.ImageMap .RegionDetails {
  position: absolute;
  bottom: -3.2rem;
  max-width: 11rem;
  overflow: hidden;
}
.ImageMap .RegionDelete {
  position: absolute;
  top: -1rem;
  right: -1rem;
  color: #fff;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  background-image: svg-load("../assets/fontawesome/svgs/duotone/times-circle.svg");
  cursor: pointer;
}
.ImageMap .RegionSelect > div {
  background-color: #800080;
  opacity: 0.8;
}
.ImageMap .upload-area {
  border-style: dashed;
  border-color: #000;
  text-align: center;
}
.ImageMap .upload-area.active {
  border-color: #008000;
}
.ImageMap .upload-area >div {
  padding: 1rem;
}
.ImageMap .image-list {
  padding: 1rem;
  text-align: center;
}
.ImageMap .image-list .remove {
  margin-left: 1rem;
}
#LessonView {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#LessonView .video {
  flex-shrink: 0;
  flex-grow: 1;
  width: 100%;
  text-align: center;
}
#LessonView .video .small-video {
  width: 30%;
  margin: auto;
  padding-bottom: 2rem;
}
#LessonView .mode {
  float: right;
  margin-left: 1rem;
  margin-right: 1rem;
}
#LessonView .links {
  text-align: center;
  padding-left: 1rem;
  padding-top: 1rem;
  padding-right: 1rem;
  flex: 0;
}
#LessonView .links a {
  font-weight: bold;
}
#LessonView .download-link {
  margin-top: 4rem;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
}
#LessonView .download-link img {
  width: 5rem;
  height: 5rem;
}
#LessonView .download-link:hover {
  animation: filter-animation 0.1s;
  filter: invert(30%) sepia(100%) hue-rotate(230deg);
}
#LessonView #SubLessons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#LessonView #SubLessons .SubLesson {
  position: relative;
  border: 0.3rem solid transparent;
  display: inline-flex;
  width: 256px;
  height: 300px;
  flex-direction: column;
  cursor: pointer;
  margin: 1rem;
}
#LessonView #SubLessons .SubLesson img {
  display: inline-block;
  width: 256px;
  height: 256px;
  object-fit: contain;
}
#LessonView #SubLessons .SubLesson .SubLesson-name {
  text-align: center;
}
#LessonView #SubLessons .SubLesson.editing {
  border-style: dashed;
  border-color: #000;
}
#LessonView #SubLessons .SubLesson.active {
  border-style: solid;
  border-color: #0f0;
}
#LessonView #SubLessons .SubLesson img {
  max-width: 320px;
  max-height: 200px;
}
#LessonView #BreadCrumbs {
  margin: 1rem;
  padding-left: 1rem;
  text-align: left;
  width: 100%;
}
#LessonView #BreadCrumbs .breadcrumb {
  font-weight: bold;
}
#LessonView #BreadCrumbs .this-breadcrumb {
  font-weight: bold;
}
#LessonView #BreadCrumbs .gt {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
#LessonView .video-upload-container {
  display: inline-flex;
  width: calc(100% - 2rem);
  min-height: 20rem;
  border: 0.3rem dashed #000;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#LessonView .video-upload-container.active {
  border-style: solid;
  border-color: #0f0;
}
#LessonView .underconstruction {
  display: inline-flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
}
#WorkArea {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#WorkArea .Work-dropzone {
  position: relative;
  cursor: pointer;
  margin-top: 2rem;
  display: inline-flex;
  min-height: 10rem;
  min-width: 10rem;
  border: 0.2rem dashed #000;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}
#WorkArea .Work-dropzone .icon-camera {
  width: 3rem;
  height: 3rem;
}
#WorkArea .Work-dropzone.active {
  border-style: solid;
  border-color: #0f0;
}
#WorkArea .WorkList {
  display: flex;
  flex-wrap: wrap;
}
#WorkArea .WorkList .entry {
  display: inline-flex;
  width: 256px;
  height: 256px;
  margin: 1rem;
  align-items: center;
  justify-content: center;
}
@-moz-keyframes filter-animation {
  0% {
    filter: invert(0%) sepia(0%) hue-rotate(0deg);
  }
  100% {
    filter: invert(30%) sepia(100%) hue-rotate(230deg);
  }
}
@-webkit-keyframes filter-animation {
  0% {
    filter: invert(0%) sepia(0%) hue-rotate(0deg);
  }
  100% {
    filter: invert(30%) sepia(100%) hue-rotate(230deg);
  }
}
@-o-keyframes filter-animation {
  0% {
    filter: invert(0%) sepia(0%) hue-rotate(0deg);
  }
  100% {
    filter: invert(30%) sepia(100%) hue-rotate(230deg);
  }
}
@keyframes filter-animation {
  0% {
    filter: invert(0%) sepia(0%) hue-rotate(0deg);
  }
  100% {
    filter: invert(30%) sepia(100%) hue-rotate(230deg);
  }
}
#Login {
  display: flex;
  flex-direction: column;
  padding-top: 4rem;
  align-items: center;
  justify-content: space-evenly;
}
#Login img#logo {
  max-width: 100vw;
}
#LoginBox {
  border: 3px solid #ce8f48;
  border-radius: 0.2rem;
  background-image: url("/assets/images/ocean-background.png");
  width: 90vw;
  height: 90vh;
  max-width: 30rem;
  max-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
#Pin,
.student {
  background-image: url("/assets/images/island-background.png");
  border: 2px solid #6e502c;
  border-radius: 0.2rem;
  filter: brightness(110%);
  padding: 0.5rem;
  font-size: 16pt;
  text-align: center;
  font-weight: bold;
  margin: 0.5rem;
}
#Pin.error,
.student.error {
  color: #f00;
}
#Bottom-wordpress-link {
  padding: 1rem;
}
#MainContainer {
  width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  overflow: hidden;
}
#MainContent {
  flex: 1;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
i.icon {
  display: inline-block;
  background-repeat: no-repeat;
  height: 1rem;
  width: 1rem;
}
i.icon.large {
  height: 3rem;
  width: 3rem;
}
i.icon-edit {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/edit.svg");
}
i.icon-camera {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/camera.svg");
}
i.icon-bars {
  background-image: svg-load("../assets/fontawesome/svgs/regular/bars.svg");
}
i.icon-home {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/home-heart.svg");
}
i.icon-scroll-old {
  background-image: svg-load("../assets/fontawesome/svgs/solid/scroll-old.svg");
}
i.icon-clock {
  background-image: svg-load("../assets/fontawesome/svgs/regular/clock.svg");
}
i.icon-close {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/window-close.svg");
}
i.icon-families {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/users.svg");
}
i.icon-progress {
  background-image: svg-load("../assets/fontawesome/svgs/duotone/poll-people.svg");
}
#Nav {
  flex-shrink: 0;
  flex-grow: 0;
  display: inline-flex;
  width: 5rem;
  flex-basis: 5rem;
  flex-direction: column;
  background-color: #d1c3ad;
  justify-content: stretch;
  text-align: center;
  font-weight: bold;
}
#Nav i.icon-edit {
  margin-left: 0.4rem;
}
#Nav .editing {
  color: #ff3d47;
}
#Nav .editing .icon-edit {
  filter: invert(60%) sepia(100%) hue-rotate(310deg) saturate(1000%);
}
#Nav a,
#Nav .logout,
#Nav .fakelink {
  cursor: pointer;
  flex: 0;
  display: inline-block;
  padding: 1rem;
}
#Nav a:hover,
#Nav .logout:hover,
#Nav .fakelink:hover {
  background-color: #e0c8a3;
}
#Nav .spacer {
  flex: 1;
}
#Nav {
  padding-top: 2rem;
}
#NavTop i.icon-bars {
  margin: 0.3rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.turnable {
  margin: 0 auto;
  user-select: none;
}
.turnable .page {
  height: 100%;
}
.turnable .page img {
  max-width: 100%;
  height: 100%;
}
.clickable-areas {
  display: relative;
  width: 100%;
  height: 100%;
}
.clickable-areas > div {
  display: inline-block;
  position: absolute;
  width: 10rem;
  height: 2rem;
  cursor: pointer;
}
.clickable-areas > div:hover {
  filter: brightness(110%);
}
#Maps .compass {
  position: absolute;
  right: 8%;
  top: 12%;
  width: 5rem;
  height: 5rem;
  text-align: center;
  font-weight: bold;
  font-family: 'Homemade Apple', cursive;
  cursor: pointer;
  color: #570038;
}
#Page-Overview .art {
  left: 2%;
  top: 30%;
  width: 10rem;
  height: 6rem;
}
#Page-Overview .geography {
  left: 25%;
  top: 10%;
  width: 12rem;
  height: 6rem;
}
#Page-Overview .biology {
  left: 50%;
  top: 8%;
  width: 10rem;
  height: 5rem;
}
#Page-Overview .home {
  left: 40%;
  top: 35%;
  width: 10rem;
  height: 8rem;
}
#Page-Overview .music {
  left: 5%;
  top: 60%;
  width: 10rem;
  height: 6rem;
}
#Page-Overview .geometry {
  left: 25%;
  top: 75%;
  width: 10rem;
  height: 5rem;
}
#Page-Overview .arithmetic {
  left: 50%;
  top: 75%;
  width: 12rem;
  height: 5rem;
}
#Page-Overview .language {
  left: 73%;
  top: 57%;
  width: 10rem;
  height: 6rem;
}
#Page-Overview .history {
  left: 73%;
  top: 28%;
  width: 10rem;
  height: 6rem;
}
.MediaThumbnail {
  display: inline-flex;
  width: 256px;
  height: 256px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.MediaThumbnail-fullscreen-view {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100000;
  background-color: #808080;
  display: inline-flex;
  flex-direction: column;
}
.MediaThumbnail-fullscreen-view .header {
  flex-grow: 0;
  flex-shrink: 0;
  height: 3rem;
  padding: 0.5rem;
  text-align: right;
}
.MediaThumbnail-fullscreen-view .header .icon-close {
  width: 2rem;
  height: 2rem;
}
.MediaThumbnail-fullscreen-view img {
  height: calc(100vh - 3rem);
  width: calc(100vw);
  object-fit: contain;
}
.MediaThumbnail-fullscreen-view iframe {
  height: calc(100vh - 3rem);
}
#PreviousDailyVideos {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#PreviousDailyVideos .entry {
  margin-top: 3rem;
  display: flex;
  align-items: center;
}
#PreviousDailyVideos .entry .date {
  padding-right: 1rem;
  font-size: 20pt;
}
#PreviousDailyVideos .entry img {
  width: 15rem;
  max-width: 100vw;
}
.RectSpinner .spinner {
  width: 50px;
  height: 20px;
  text-align: center;
  font-size: 10px;
}
.RectSpinner .spinner > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  margin-left: 2px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.RectSpinner .spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.RectSpinner .spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.RectSpinner .spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.RectSpinner .spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}
@-moz-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@-o-keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.Spinner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
}
.sk-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}
.sk-circle .sk-child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.sk-circle .sk-child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
  animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.sk-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.sk-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.sk-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.sk-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.sk-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.sk-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.sk-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.sk-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.sk-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.sk-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.sk-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}
@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-moz-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-o-keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes sk-circleBounceDelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
#StudentSelect {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
#StudentSelectBox {
  border: 3px solid #ce8f48;
  border-radius: 0.2rem;
  background-image: url("/assets/images/ocean-background.png");
  width: 90vw;
  height: 90vh;
  max-width: 30rem;
  max-height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#StudentSelectBox .student {
  background-image: url("/assets/images/island-background.png");
  border: 2px solid #6e502c;
  border-radius: 0.2rem;
  filter: brightness(110%);
  padding: 0.5rem;
  font-size: 16pt;
  text-align: center;
  font-weight: bold;
  margin: 0.5rem;
  width: 10rem;
  max-width: 80vw;
  cursor: pointer;
}
#StudentSelectBox .student:hover {
  border-color: #570038;
  color: #570038;
}
#StudentSelectBox .student.error {
  color: #f00;
}
#Uploader {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 10000;
  background-color: #eee;
  width: 17rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 2px solid #000;
  border-left: 2px solid #000;
  border-radius-top-left: 1rem;
  font-weight: bold;
}
#Uploader .Uploader-entry {
  display: flex;
  justify-content: center;
  align-items: center;
}
#Uploader .Uploader-entry .Uploader-filename {
  display: inline-block;
  width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 10em;
}
#Uploader .Uploader-entry .Uploader-spinner {
  display: inline-block;
  width: 5rem;
}
#ManageFamilies .Family-entry {
  margin: 1rem;
  margin-top: 2rem;
  border-left: 0.3rem solid #eed;
  padding: 0.5rem;
}
#ManageFamilies .Family-Student-entry {
  margin-left: 2rem;
  margin-top: 0.3rem;
}
#ManageFamilies .new-family {
  margin-left: 3rem;
}
#ManageFamilies .pin {
  width: 4rem;
}
#ManageFamilies input,
#ManageFamilies select,
#ManageFamilies button {
  margin-right: 0.5rem;
}
#ManageFamilies .unsaved {
  color: #46960e;
}
#ReviewWork {
  display: flex;
  flex-direction: column;
}
#ReviewWork .no-work-to-review {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
}
#ReviewWork .ReviewWork-container {
  display: flex;
  align-items: center;
}
#ReviewWork .Work-entry {
  display: flex;
  align-items: center;
  justify-content: center;
}
#ReviewWork .Work-entry button {
  margin: 1rem;
}
#ReviewWork .Work-entry.reviewed {
  opacity: 0.5;
  background-color: #ddc;
}
#ReviewWork .student-lesson-time {
  display: inline-flex;
  flex-direction: column;
  width: 15rem;
  margin: 1rem;
}
#ReviewWork .student-lesson-time .lesson-name {
  margin-left: 2rem;
}
#ReviewWork .student-lesson-time .time {
  margin-left: 2rem;
  font-style: italic;
}
#ReviewWork .media-thumbnail {
  display: inline-flex;
  margin: 1rem;
  width: 256px;
  height: 256px;
  align-items: center;
  justify-content: center;
}
#ReviewWork .review-button-container {
  display: inline-block;
  width: 10rem;
}
#Curriculum #curriculum-entries {
  margin-top: 2rem;
  text-align: center;
}
#Curriculum #curriculum-entries .curriculum-table-container {
  text-align: left;
  display: inline-flex;
  width: 18rem;
  padding: 0.5rem;
  flex-direction: column;
}
#Curriculum #curriculum-entries .curriculum-table-container .header {
  font-weight: bold;
}
#Curriculum .curriculum-table {
  border-collapse: collapse;
}
#Curriculum .curriculum-table td,
#Curriculum .curriculum-table th {
  border-top: 1px solid #000;
  vertical-align: top;
}
#Curriculum .curriculum-table th {
  padding-top: 1rem;
}
#FAQ .question {
  margin-top: 2rem;
  font-weight: bold;
}
#FAQ .answer {
  padding-left: 2rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}
#LandingMain {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
#LandingMain #NavBar {
  flex: 0;
}
#LandingMain #LandingContent-container {
  flex: 1;
  overflow: auto;
}
#LandingMain #LandingContent {
  padding-top: 2rem;
  display: inline-block;
  width: 40rem;
  text-align: justify;
  max-width: 100vw;
  margin: auto;
}
#LandingMain #logo {
  max-width: 100vw;
}
#NavBar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}
#NavBar a {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border: 1px solid;
  background-color: #66023c;
  margin: 0.3rem;
  color: #ebe0bc;
}
#NavBar a:hover,
#NavBar a .active {
  color: #fff;
}
#Tuition .note {
  padding-left: 2rem;
}
