#StudentSelect {
    display: flex;
    flex-direction: column;

    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

#StudentSelectBox {
    border: 3px solid #CE8F48;
    border-radius: 0.2rem;
    background-image: url('/assets/images/ocean-background.png');
    width: 90vw;
    height: 90vh;
    max-width: 30rem;
    max-height: 10rem; // 16:9
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;


    .student {
        background-image: url('/assets/images/island-background.png');
        border: 2px solid #6E502C;
        border-radius: 0.2rem;
        filter: brightness(110%);
        padding: 0.5rem;
        font-size: 16pt;
        text-align: center;
        font-weight: bold;
        margin: 0.5rem;
        width: 10rem;
        max-width: 80vw;

        cursor: pointer;
        &:hover {
            border-color: #570038;
            color: #570038;
        }

        &.error {
            color: red;
        }
    }

}
