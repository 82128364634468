#Login {
    display: flex;
    flex-direction: column;

    padding-top: 4rem;
    //width: 100vw;
    //height: 100vh;
    align-items: center;
    justify-content: space-evenly;

    img#logo {
        max-width: 100vw;
    }

}

#LoginBox {
    border: 3px solid #CE8F48;
    border-radius: 0.2rem;
    background-image: url('/assets/images/ocean-background.png');
    width: 90vw;
    height: 90vh;
    max-width: 30rem;
    max-height: 10rem; // 16:9
    display: flex;
    align-items: center;
    justify-content: center;
}

#Pin, .student {
    background-image: url('/assets/images/island-background.png');
    border: 2px solid #6E502C;
    border-radius: 0.2rem;
    filter: brightness(110%);
    padding: 0.5rem;
    font-size: 16pt;
    text-align: center;
    font-weight: bold;
    margin: 0.5rem;

    &.error {
        color: red;
    }
}

#Bottom-wordpress-link {
    padding: 1rem;
}
