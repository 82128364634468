.MediaThumbnail {
    display: inline-flex;
    width: 256px;
    height: 256px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.MediaThumbnail-fullscreen-view {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    background-color: gray;
    display: inline-flex;
    flex-direction: column;

    .header {
        flex-grow: 0;
        flex-shrink: 0;
        height: 3rem;
        padding: 0.5rem;
        text-align: right;
        .icon-close {
            width: 2rem;
            height: 2rem;
        }
    }

    img {
        height: calc(100vh - 3rem);
        width: calc(100vw);
        object-fit: contain;
    }

    iframe {
        height: calc(100vh - 3rem);
    }

}
