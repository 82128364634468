primary-house-width=1749;
primary-house-height=1448;
primary-house-aspect=primary-house-width / primary-house-height;

elementary-house-width=1920;
elementary-house-height=1080;
elementary-house-aspect=elementary-house-width / elementary-house-height;

#CirriculumView {
    display: flex;
    flex: 1;
}


#PrimaryMap {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    #MapContainer {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;

        background-image: url(/assets/images/primary-house.jpg);
        background-size: contain;
        background-repeat: no-repeat;
        //background-size: 800px 600px;
        
        @media (orientation: landscape) {
            height: 100vh;
            width: 'calc(100vh * %s)' % primary-house-aspect;
        }
        @media (orientation: portrait) {
            width: 100vw;
            height: 'calc(100vw / %s)' % primary-house-aspect;
        }



        //width: 800px;
        //height: 600px


        #practical-life, #math-and-science, #sensorial, #language, #cultural {
            display: inline-block;
            position: absolute;
            z-index: 1000;
            //background-color: red;
            //opacity: 0.3;

            &:hover {
                cursor: pointer;
                background-image: url(/assets/images/primary-house-highlighted.jpg);
                //background-size: "calc(90vw - %s)" % left-nav-width ;
                background-size: contain;
                background-repeat: no-repeat;


                @media (orientation: landscape) {
                    background-size: 'calc(100vh * %s)' % primary-house-aspect 100vh;
                }
                @media (orientation: portrait) {
                    background-size: 100vw 'calc(100vw / %s)' % primary-house-aspect;
                }

            }
        }

        #practical-life {
            left: 20%;
            top: 58%;
            width: 20%;
            height: 15%;
            
            background-position: left 25% top 68%;
        }
        #sensorial {
            left: 61%;
            top: 59%;
            width: 20%;
            height: 15%;

            background-position: left 74% top 69%;
        }
        #language {
            left: 17%;
            top: 26%;
            width: 16%;
            height: 15%;

            background-position: left 20% top 30.5%;
        }
        #math-and-science {
            left: 61%;
            top: 26%;
            width: 18%;
            height: 15%;

            background-position: left 75% top 30.5%;
        }
        #cultural{
            left: 39.5%;
            top: 56%;
            width: 18%;
            height: 34%;

            background-position: left 48% top 85.0%;
        }
    }
}


#ElementaryMap {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    #MapContainer {
        display: inline-block;
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;

        background-image: url(/assets/images/Mont-Map-overview.jpg);
        background-size: contain;
        background-repeat: no-repeat;
        //background-size: 800px 600px;
        
        @media (orientation: landscape) {
            height: 70vh;
            width: 'calc(70vh * %s)' % elementary-house-aspect;
        }
        @media (orientation: portrait) {
            width: 100vw;
            height: 'calc(100vw / %s)' % elementary-house-aspect;
        }



        //width: 800px;
        //height: 600px


        > div {
            display: inline-block;
            position: absolute;
            z-index: 1000;

            &:hover {
                background-color: green;
                opacity: 0.3;
                cursor: pointer;


                //background-image: url(/assets/images/elementary-house-highlighted.jpg);
                background-size: contain;
                background-repeat: no-repeat;


                @media (orientation: landscape) {
                    background-size: 'calc(100vh * %s)' % elementary-house-aspect 100vh;
                }
                @media (orientation: portrait) {
                    background-size: 100vw 'calc(100vw / %s)' % elementary-house-aspect;
                }

            }
        }

        #arithmetic {
            left: 50%;
            top: 69%;
            width: 16%;
            height: 17%;
            
            background-position: left 25% top 68%;
        }
        #art {
            left: 14%;
            top: 24%;
            width: 12%;
            height: 24%;

            background-position: left 74% top 69%;
        }
        #biology {
            left: 50%;
            top: 15%;
            width: 18%;
            height: 17%;

            background-position: left 20% top 30.5%;
        }
        #geography {
            left: 30%;
            top: 16%;
            width: 20%;
            height: 20%;

            background-position: left 75% top 30.5%;
        }
        #geometry {
            left: 27%;
            top: 69%;
            width: 20%;
            height: 17%;
            
            background-position: left 25% top 68%;
        }
        #history {
            left: 71.0%;
            top: 35%;
            width: 18%;
            height: 20%;

            background-position: left 48% top 85.0%;
        }
        #language {
            left: 71.5%;
            top: 59%;
            width: 18%;
            height: 18%;

            background-position: left 48% top 85.0%;
        }
        #music {
            left: 13.0%;
            top: 54%;
            width: 14%;
            height: 23%;

            background-position: left 48% top 85.0%;
        }
    }
}
