#NavBar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;


    a {
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        border: 1px solid ;
        background-color: #66023C;
        margin: 0.3rem;
        color: #EBE0BC;

        &:hover, .active {
        color: white;
        }
    }
}
