.ImageMap {
    display: inline-block;
    margin: auto;


    &.editing {
        img {
            /*
            width: 1920px;
            height: 1080px;
            */
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    .map-container {
        display: inline-block;
        position: relative;

        .region {
            position: absolute;
            display: inline-block;
            cursor: pointer;
            height: 100%;
            width: 100%;

            &.highlight {
                background-color: purple;
                opacity: 0.5;
            }
        }
    }

    .RegionDetails {
        position: absolute;
        bottom: -3.2rem;
        max-width: 11rem;
        overflow: hidden;
    }

    .RegionDelete {
        position: absolute;
        top: -1rem;
        right: -1rem;
        color: white;
        display: inline-block;
        width: 1rem;
        height: 1rem;
        text-align: center;
        background-image: svg-load("../assets/fontawesome/svgs/duotone/times-circle.svg");
        cursor: pointer;

    }

    .RegionSelect {
        > div {
            background-color: purple;
            opacity: 0.8;
        }
    }

    .upload-area {
        border-style: dashed;
        border-color: #000;
        text-align: center;

        &.active {
            border-color: green;
        }

        >div {
            padding: 1rem;
        }
    }

    .image-list {
        padding: 1rem;
        text-align: center;
        .remove {
            margin-left: 1rem;
        }
    }

}
